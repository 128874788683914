import { useQuery } from '@tanstack/vue-query'
import {
  ActionRequestResponseDto,
  ActionRequestType,
  SignatureReleaseActionRequestResponseDto,
} from '@torticity/types'
import orderBy from 'lodash/orderBy.js'

export function useActionRequestsQuery(
  filterById?: string | null,
  filterByStatus?: string[],
  filterBySignatureStatus?: string[],
) {
  const { $http } = useNuxtApp()

  async function getActionRequests(): Promise<ActionRequestResponseDto[]> {
    let actionRequests: ActionRequestResponseDto[]

    if (filterById) {
      actionRequests = await $http.$get(`/v1/action-requests/${filterById}`)
    } else {
      actionRequests = await $http.$get(`/v1/action-requests`)

      if (filterByStatus) {
        actionRequests = actionRequests.filter((d: { status: string }) =>
          filterByStatus.includes(d.status),
        )
      }

      if (filterBySignatureStatus) {
        actionRequests = actionRequests.filter((d: ActionRequestResponseDto) => {
          const { type, signatureRelease } = d as SignatureReleaseActionRequestResponseDto
          if (type === ActionRequestType.SIGNATURE_RELEASE) {
            return filterBySignatureStatus.includes(signatureRelease.status)
          }
          return true
        })
      }
    }

    return actionRequests.length
      ? orderBy(actionRequests, ar => Number(ar.id), 'desc')
      : actionRequests
  }

  return useQuery({
    queryFn: getActionRequests,
    queryKey: ['action-requests', filterById, filterByStatus, filterBySignatureStatus],
  })
}
